import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"

const Privacidad = () => (
  <Layout>
    <Seo title="Politicas de privacidad" />
    <div className="text-center pt-6 pb-6">
      <Container>
        <h1>Políticas de privacidad</h1>
      </Container>
    </div>

    <Container>
      <div className="text-justify pb-5">
        <p>
          Al realizar una compra de algún servicio a la compañía PUNTOHOST.COM,
          el cliente acepta expresamente y en su totalidad los acuerdos
          denominados "Términos del servicio" y "Políticas de privacidad". Así
          mismo, el cliente acepta que todas las normas y lineamientos
          contenidos en los apartados antes mencionados pueden ser variadas por
          PUNTOHOST.COM sin previo aviso en el momento que la compañía así lo
          crea conveniente. Por tal motivo será responsabilidad del cliente
          consultar regularmente los documentos mencionados y estar al tanto de
          su contenido. La continuidad del uso del servicio por parte del
          cliente constituye su aceptación a los cambios hechos en los acuerdos
          mencionados, si el cliente no está de acuerdo con los términos del
          servicio debe notificar inmediatamente a PUNTOHOST.COM y detener el
          uso del servicio.
        </p>
        <p>
          La relación entre el cliente y la compañía PUNTOHOST.COM, serán
          gobernadas por las leyes de la República Bolivariana de Venezuela.
          Todas las disputas, controversias y reclamos causados por o
          relacionadas con estos términos y condiciones serán resueltos por las
          leyes venezolanas en las instancias correspondientes.
        </p>
        <p>
          ES LA SUPOSICIÓN DE PUNTOHOST.COM QUE EL CLIENTE ADQUIERE UN SERVICIO
          SÓLO DESPUÉS DE DETERMINAR QUE AL HACERLO NO VIOLA NINGUNA LEY NI
          REGULACIÓN EN SU PAÍS Y JURISDICCIÓN. Así mismo el cliente garantiza
          que no está violando ninguna ley ni regulación con su uso del servicio
          y exime a la compañía PUNTOHOST.COM de toda responsabilidad en caso de
          cualquier violación.
        </p>
        <p>
          El Cliente está de acuerdo con liberar, indemnizar y no culpar a la
          compañía de cualquier reclamo que se haga en contra de PUNTOHOST.COM
          por una tercera empresa o persona que resulte afectada con el uso del
          servicio adquirido por parte del cliente.
        </p>

        <p class="s-title">DECLARACIÓN DE PRIVACIDAD - PROTECCIÓN DE DATOS</p>
        <p>
          En tanto que el cliente dé un uso legal a los recursos provistos por
          PUNTOHOST.COM en la prestación del servicio, la compañía se compromete
          a no revelar ni dar permiso expreso para utilizar la información
          personal que obtenga de parte del cliente en la realización de una
          transacción y asegura que los datos aportados por el cliente de la
          manera descrita solamente serán utilizados por la compañía para fines
          relacionados con la prestación del servicio adquirido por el mismo.
        </p>
        <p>
          En el caso de que el cliente registre un nombre de Dominio en
          PUNTOHOST.COM, autoriza expresamente a PUNTOHOST.COM, a utilizar su
          información personal en el trámite de registro, para que sea el
          cliente quien figure como propietario del Dominio adquirido ante la
          entidad registrante. La entidad registrante tendrá la potestad de
          publicar los datos usados en el trámite de registro del dominio en su
          base de datos WHOIS, y el cliente exime a PUNTOHOST.COM de cualquier
          responsabilidad por la publicación y uso de estos datos.
        </p>
        <p>
          PUNTOHOST.COM prohíbe el uso de sus servicios en actividades ilegales.
          Por lo tanto, el cliente acepta que la compañía puede entregar
          cualquier información de su pertenencia incluidas las direcciones IP
          asignadas, historial de la cuenta, uso de la cuenta, datos personales
          y financieros, etc., a cualquier agencia o institución policial o de
          refuerzo de la ley que haga una solicitud, sin previa notificación al
          suscriptor. Adicionalmente en dado caso PUNTOHOST.COM tendrá el
          derecho de terminar la prestación de los servicios hacia el cliente en
          caso de utilización de sus recursos para cometer actos ilícitos.
        </p>

        <p class="s-title">INFORMACIÓN PERSONAL</p>
        <p>
          Si la información personal de un usuario cambia, es responsabilidad
          del usuario informar a PUNTOHOST.COM acerca de los cambios. Por tanto,
          cualquier inconveniente presentado en la prestación del servicio
          adquirido por el cliente, y que sea causado por la variación de dichos
          datos, será responsabilidad única y exclusivamente del cliente.
        </p>
        <p class="s-title">COMUNICACIÓN</p>
        <p>
          El cliente autoriza a PUNTOHOST.COM a enviarle ocasionalmente
          información sobre los productos, servicios y ofertas especiales de la
          compañía por email o vía boletín electrónico. De darse este caso,
          presentaremos la opción al cliente de no recibir este tipo de
          comunicaciones.
        </p>
        <p class="s-title">ENLACES EXTERNOS</p>
        <p>
          Nuestro sitio web contiene enlaces a otros sitios web ajenos a nuestra
          compañía por lo que el cliente debe saber que PUNTOHOST.COM no es
          responsable de las prácticas de privacidad provistas en tales sitios.
          Esta declaración de privacidad se aplica solamente a información
          coleccionada por el sitio web de PUNTOHOST.COM.
        </p>

        <p class="s-title">SEGURIDAD</p>
        <p>
          Las transacciones y pagos en línea serán manejados por compañías
          ajenas a PUNTOHOST.COM. Por tanto, aunque PUNTOHOST.COM brinda toda su
          confianza a sus procesadores de órdenes, no es responsable por el
          manejo que estos realicen de los datos aportados por el usuario.
        </p>
        <p class="s-title">APP ANDROID (Aplicaciones Android)</p>
        <p>
          ¿Qué información obtiene las aplicaciones y como es utilizada? Las
          aplicaciones pueden recopilar de forma automática información, como
          puede ser: tipo de dispositivo móvil, país del usuario, dirección IP,
          el sistema operativo del dispositivo móvil e información de cómo se
          usan las aplicaciones. ¿Las aplicaciones obtienen la información
          precisa y en tiempo real del dispositivo? Las aplicaciones no
          recopilan información precisa y en tiempo real de la ubicación del
          dispositivo en ningún momento. ¿Pueden terceros obtener acceso a la
          información obtenida por el aplicativo? Si. Podemos compartir la
          información proporcionada por el usuario o la obtenida automáticamente
          en los siguientes casos: - Cuando sea requerido por la ley. - Cuando
          creamos que es necesario para proteger nuestros derechos, proteger su
          seguridad o la seguridad de otros, investigar fraude o en respuesta a
          una solicitud del gobierno.
        </p>
        <p class="s-title">COOKIES</p>
        <p>
          El acceso a este sitio puede implicar la utilización de cookies. Las
          cookies son pequeñas cantidades de información que se almacenan en el
          navegador utilizado por cada usuario para que el servidor recuerde
          cierta información que posteriormente pueda utilizar. Esta información
          permite identificarle a usted como un usuario concreto y permite
          guardar sus preferencias personales, así como información técnica como
          puedan ser visitas o páginas concretas que visite.
        </p>

        <p>
          Aquellos usuarios que no deseen recibir cookies o quieran ser
          informados antes de que se almacenen en su ordenador, pueden
          configurar su navegador a tal efecto. La mayor parte de los
          navegadores de hoy en día permiten la gestión de las cookies de 3
          formas diferentes: – Las cookies no se aceptan nunca. – El navegador
          pregunta al usuario si se debe aceptar cada cookie. – Las cookies se
          aceptan siempre.
        </p>

        <p>
          El navegador también puede incluir la posibilidad de especificar mejor
          qué cookies tienen que ser aceptadas y cuáles no. En concreto, el
          usuario puede normalmente aceptar alguna de las siguientes opciones:
          rechazar las cookies de determinados dominios; rechazar las cookies de
          terceros; aceptar cookies como no persistentes (se eliminan cuando el
          navegador se cierra); permitir al servidor crear cookies para un
          dominio diferente. Además, los navegadores pueden también permitir a
          los usuarios ver y borrar cookies individualmente.
        </p>

        <p class="s-title">CAMBIOS</p>
        <p>
          Este acuerdo de “Políticas de privacidad” puede cambiar en cualquier
          momento y el cliente acepta que todas las normas y lineamientos
          contenidos en el pueden ser variadas por PUNTOHOST.COM sin previo
          aviso en el momento que la compañía así lo crea conveniente. Por tal
          motivo será responsabilidad del cliente consultar regularmente este
          apartado y estar al tanto de su contenido.
        </p>
        <p>
          Al adquirir o suscribirse a un servicio de PUNTOHOST.COM el cliente se
          compromete a cumplir con este acuerdo en su totalidad.
        </p>
      </div>
    </Container>
  </Layout>
)

export default Privacidad
